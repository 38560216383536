import React, { Component } from "react";
import Compiler from "./Components/Compiler/Compiler";
export default class App extends Component {
  render() {
    return (
      <>
        <Compiler />
      </>
    );
  }
}
